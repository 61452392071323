/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { CELL_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const CellStatus = props => {
  const { cell, ...rest } = props;

  const statusColors = {
    ACTIVE: colors.green[600],
    PRE_PRODUCTION: colors.lime[600],
    LAB_PRODUCTION: colors.teal[600],
    MARKETING: colors.teal[600],
    FIELD_PRODUCTION: colors.green[600],
    DEVELOPMENT: colors.blue[600],
    DISASSEMBLED: colors.grey[500],
    DECOMMISSIONED: colors.grey[600],
    BUILD_ACTIVE: colors.purple[600],
    TESTING: colors.orange[600],
    VERIFIED: colors.green[600]
  };

  return (
    <div>
      {cell && cell.status && (
        <Label {...rest} color={statusColors[cell.status]}>
          {CELL_STATUSES[cell.status]}
        </Label>
      )}
    </div>
  );
};

CellStatus.propTypes = {
  cell: PropTypes.object
};

export default CellStatus;
