/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { SOFTWARE_PACKAGE_STATUS_COLORS } from 'common/constants';

const useStyles = makeStyles(theme => ({
  label: {
    marginRight: theme.spacing(1)
  },
  link: {
    cursor: 'pointer'
  }
}));

const MonitoredSoftwareVersions = props => {
  const classes = useStyles();

  const { softwareVersions } = props;

  const PACKAGES = {
    'xonar-cell-credentials': { label: 'Credentials', column: 3 },
    'xonar-mqtt-tls': { label: 'MQTT TLS', column: 4 },
    'xonar-cell-os-apt': { label: 'Cell OS', column: 2 },
    'xonar-cell-software': { label: 'Cell Software', column: 0 },
    'xonar-cell-software-docker': { label: 'Cell Software', column: 0 },
    'xonar-operator-middleware': { label: 'Middleware', column: 1 },
    'xonar-api-credentials': { label: 'Credentials', column: 3 }
  };

  const indexedPackages = [];
  for (let i = 0; i < 5; i++) {
    const sw = softwareVersions?.filter(
      sw => PACKAGES[sw.name]?.column === i
    )[0];
    indexedPackages.push(sw ? sw : null);
  }

  const Version = props => {
    const { softwareVersion } = props;

    return (
      <Label
        key={softwareVersion.name}
        color={
          SOFTWARE_PACKAGE_STATUS_COLORS[
            softwareVersion.softwarePackage?.status
          ]
        }
        className={clsx(
          classes.label,
          softwareVersion.softwarePackage?.id ? classes.link : ''
        )}
        onClick={() =>
          softwareVersion.softwarePackage?.id
            ? (window.location = `/software/packages/${softwareVersion.softwarePackage?.id}/summary`)
            : undefined
        }>
        {PACKAGES[softwareVersion.name]
          ? PACKAGES[softwareVersion.name].label
          : softwareVersion.name}{' '}
        / {softwareVersion.versionLessCommitHash}
      </Label>
    );
  };

  return (
    <>
      {indexedPackages &&
        indexedPackages.map((software, i) => (
          <TableCell style={{ width: 20, padding: 0 }} key={i}>
            {software && <Version softwareVersion={software} />}
          </TableCell>
        ))}
    </>
  );
};

MonitoredSoftwareVersions.propTypes = {
  softwareVersions: PropTypes.array
};

export default MonitoredSoftwareVersions;
