/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import PhishingSimulationLayout from './layouts/PhishingSimulation';
import DashboardLayout from './layouts/Dashboard';
import { HomeRedirect } from 'components';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <HomeRedirect />
  },
  {
    path: '/documents/retrieval',
    component: PhishingSimulationLayout,
    routes: [
      {
        path: '/documents/retrieval',
        exact: true,
        component: lazy(() => import('views/PhishingSimulation'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/logout',
        exact: true,
        component: lazy(() => import('views/Logout'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: AuthLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        path: '/errors/error',
        exact: true,
        component: lazy(() => import('views/Error'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/walks/cell_walks/',
        exact: true,
        component: lazy(() => import('views/EventList'))
      },
      {
        path: '/walks/analysis/',
        exact: true,
        component: lazy(() => import('views/TestingAnalysis'))
      },
      {
        path: '/walks/analysis/:tab',
        exact: true,
        component: lazy(() => import('views/TestingAnalysis'))
      },
      {
        path: '/walks/local_storage/',
        exact: true,
        component: lazy(() => import('views/WalkLocalStorage'))
      },
      {
        path: '/walks/local_storage/:tab',
        exact: true,
        component: lazy(() => import('views/WalkLocalStorage'))
      },
      {
        path: '/:scenarioTypeCode/scenarios/',
        exact: true,
        component: lazy(() => import('views/SetupScenarioList'))
      },
      {
        path: '/:scenarioTypeCode/scenarios/create',
        exact: true,
        component: lazy(() => import('views/SetupScenarioCreate'))
      },
      {
        path: '/:scenarioTypeCode/scenarios/:id/edit',
        exact: true,
        component: lazy(() => import('views/SetupScenarioCreate'))
      },
      {
        path: '/:scenarioTypeCode/scenarios/:id',
        exact: true,
        component: lazy(() => import('views/SetupScenarioDetails'))
      },
      {
        path: '/:scenarioTypeCode/scenarios/:id/:tab',
        exact: true,
        component: lazy(() => import('views/SetupScenarioDetails'))
      },
      {
        path: '/testing/sessions/',
        exact: true,
        component: lazy(() => import('views/TestSessionList'))
      },
      {
        path: '/testing/dashboard/',
        exact: true,
        component: lazy(() => import('views/TestingDashboard'))
      },
      {
        path: '/testing/dashboard/:tab',
        exact: true,
        component: lazy(() => import('views/TestingDashboard'))
      },
      {
        path: '/testing/sessions/:id',
        exact: true,
        component: lazy(() => import('views/TestSessionDetails'))
      },
      {
        path: '/testing/scheduled/',
        exact: true,
        component: lazy(() => import('views/TestSessionUserScheduledList'))
      },
      {
        path: '/testing/sessions/:id/:tab',
        exact: true,
        component: lazy(() => import('views/TestSessionDetails'))
      },
      {
        path: '/training/sessions/',
        exact: true,
        component: lazy(() => import('views/TrainingSessionList'))
      },
      {
        path: '/training/cells_overview/',
        exact: true,
        component: lazy(() => import('views/TrainingCellsOverview'))
      },
      {
        path: '/training/dashboard/',
        exact: true,
        component: lazy(() => import('views/TrainingDashboard'))
      },
      {
        path: '/training/dashboard/:tab',
        exact: true,
        component: lazy(() => import('views/TrainingDashboard'))
      },
      {
        path: '/training/sessions/:id',
        exact: true,
        component: lazy(() => import('views/TrainingSessionDetails'))
      },
      {
        path: '/training/sessions/:id/:tab',
        exact: true,
        component: lazy(() => import('views/TrainingSessionDetails'))
      },
      {
        path: '/training/compatibility_groups/',
        exact: true,
        component: lazy(() => import('views/TrainingCompatibilityGroupList'))
      },
      {
        path: '/training/compatibility_groups/create',
        exact: true,
        component: lazy(() => import('views/TrainingCompatibilityGroupCreate'))
      },
      {
        path: '/training/compatibility_groups/:id/edit',
        exact: true,
        component: lazy(() => import('views/TrainingCompatibilityGroupCreate'))
      },
      {
        path: '/training/compatibility_groups/:id',
        exact: true,
        component: lazy(() => import('views/TrainingCompatibilityGroupDetails'))
      },
      {
        path: '/training/compatibility_groups/:id/:tab',
        exact: true,
        component: lazy(() => import('views/TrainingCompatibilityGroupDetails'))
      },

      {
        path: '/walks/data_sets/create',
        exact: true,
        component: lazy(() => import('views/EventDataSetCreate'))
      },
      {
        path: '/walks/data_sets/:id/edit',
        exact: true,
        component: lazy(() => import('views/EventDataSetCreate'))
      },
      {
        path: '/walks/data_sets/',
        exact: true,
        component: lazy(() => import('views/EventDataSetList'))
      },
      {
        path: '/walks/data_sets/:id',
        exact: true,
        component: lazy(() => import('views/EventDataSetDetails'))
      },
      {
        path: '/walks/data_sets/:id/:tab',
        exact: true,
        component: lazy(() => import('views/EventDataSetDetails'))
      },
      {
        path: '/events/dashboard/',
        exact: true,
        component: lazy(() => import('views/CustomerEventDashboard'))
      },
      {
        path: '/events/dashboard/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerEventDashboard'))
      },
      {
        path: '/events/customer_events/',
        exact: true,
        component: lazy(() => import('views/CustomerEventList'))
      },
      {
        path: '/events/calendar/',
        exact: true,
        component: lazy(() => import('views/CustomerEventCalendar'))
      },
      {
        path: '/events/customer_events/create',
        exact: true,
        component: lazy(() => import('views/CustomerEventCreate'))
      },
      {
        path: '/events/customer_events/:id/edit',
        exact: true,
        component: lazy(() => import('views/CustomerEventCreate'))
      },
      {
        path: '/events/customer_events/:id',
        exact: true,
        component: lazy(() => import('views/CustomerEventDetails'))
      },
      {
        path: '/events/customer_events/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerEventDetails'))
      },
      {
        path: '/deployment/cell_check_list_definitions/',
        exact: true,
        component: lazy(() => import('views/CellCheckListDefinitionList'))
      },
      {
        path: '/deployment/cell_check_list_definitions/:id/edit',
        exact: true,
        component: lazy(() => import('views/CellCheckListDefinitionCreate'))
      },
      {
        path: '/deployment/cell_check_list_definitions/:id',
        exact: true,
        component: lazy(() => import('views/CellCheckListDefinitionDetails'))
      },
      {
        path: '/deployment/cell_check_list_definitions/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CellCheckListDefinitionDetails'))
      },
      {
        path: '/deployment/cell_build_definitions/',
        exact: true,
        component: lazy(() => import('views/CellBuildDefinitionList'))
      },
      {
        path: '/deployment/cell_build_definitions/:id/edit',
        exact: true,
        component: lazy(() => import('views/CellBuildDefinitionCreate'))
      },
      {
        path: '/deployment/cell_build_definitions/:id',
        exact: true,
        component: lazy(() => import('views/CellBuildDefinitionDetails'))
      },
      {
        path: '/deployment/cell_build_definitions/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CellBuildDefinitionDetails'))
      },
      {
        path: '/deployment/cell_model_versions/',
        exact: true,
        component: lazy(() => import('views/CellModelVersionList'))
      },
      {
        path: '/deployment/cell_model_versions/create',
        exact: true,
        component: lazy(() => import('views/CellModelVersionCreate'))
      },
      {
        path: '/deployment/cell_model_versions/:id/edit',
        exact: true,
        component: lazy(() => import('views/CellModelVersionCreate'))
      },
      {
        path: '/deployment/cell_model_versions/:id',
        exact: true,
        component: lazy(() => import('views/CellModelVersionDetails'))
      },
      {
        path: '/deployment/cell_model_versions/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CellModelVersionDetails'))
      },
      {
        path: '/deployment/cells/',
        exact: true,
        component: lazy(() => import('views/CellList'))
      },
      {
        path: '/deployment/cells_map',
        exact: true,
        component: lazy(() => import('views/CellMap'))
      },
      {
        path: '/deployment/cell_config/',
        exact: true,
        component: lazy(() => import('views/MqttSelectInterface'))
      },
      {
        path: '/deployment/cell_config/edit_cells',
        exact: true,
        component: lazy(() => import('views/MqttEditInterface'))
      },
      {
        path: '/deployment/cells/create',
        exact: true,
        component: lazy(() => import('views/CellCreate'))
      },
      {
        path: '/deployment/cells/:id/edit',
        exact: true,
        component: lazy(() => import('views/CellCreate'))
      },
      {
        path: '/deployment/cells/:id',
        exact: true,
        component: lazy(() => import('views/CellDetails'))
      },
      {
        path: '/deployment/cells/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CellDetails'))
      },
      {
        path: '/deployment/cell_health_reports/',
        exact: true,
        component: lazy(() => import('views/CellHealthReportList'))
      },
      {
        path: '/deployment/cell_log_entries/',
        exact: true,
        component: lazy(() => import('views/CellLogEntryList'))
      },
      {
        path: '/deployment/cell_log_entries/:id',
        exact: true,
        component: lazy(() => import('views/CellLogEntryDetails'))
      },
      {
        path: '/deployment/radars/',
        exact: true,
        component: lazy(() => import('views/CellRadarList'))
      },
      {
        path: '/deployment/radars/create',
        exact: true,
        component: lazy(() => import('views/CellRadarCreate'))
      },
      {
        path: '/deployment/radars/:id/edit',
        exact: true,
        component: lazy(() => import('views/CellRadarCreate'))
      },
      {
        path: '/deployment/radars/:id',
        exact: true,
        component: lazy(() => import('views/CellRadarDetails'))
      },
      {
        path: '/deployment/radars/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CellRadarDetails'))
      },
      {
        path: '/deployment/master_versions/',
        exact: true,
        component: lazy(() => import('views/MasterVersionList'))
      },
      {
        path: '/deployment/master_versions/create',
        exact: true,
        component: lazy(() => import('views/MasterVersionCreate'))
      },
      {
        path: '/deployment/master_versions/:id/edit',
        exact: true,
        component: lazy(() => import('views/MasterVersionCreate'))
      },
      {
        path: '/deployment/master_versions/:id',
        exact: true,
        component: lazy(() => import('views/MasterVersionDetails'))
      },
      {
        path: '/deployment/master_versions/:id/:tab',
        exact: true,
        component: lazy(() => import('views/MasterVersionDetails'))
      },
      {
        path: '/deployment/cell_components/',
        exact: true,
        component: lazy(() => import('views/CellComponentList'))
      },
      {
        path: '/deployment/cell_components/create',
        exact: true,
        component: lazy(() => import('views/CellComponentCreate'))
      },
      {
        path: '/deployment/cell_components/:id/edit',
        exact: true,
        component: lazy(() => import('views/CellComponentCreate'))
      },
      {
        path: '/deployment/cell_components/:id',
        exact: true,
        component: lazy(() => import('views/CellComponentDetails'))
      },
      {
        path: '/deployment/cell_components/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CellComponentDetails'))
      },
      {
        path: '/reference/participants/',
        exact: true,
        component: lazy(() => import('views/ParticipantList'))
      },
      {
        path: '/reference/participants/create',
        exact: true,
        component: lazy(() => import('views/ParticipantCreate'))
      },
      {
        path: '/reference/participants/:id/edit',
        exact: true,
        component: lazy(() => import('views/ParticipantCreate'))
      },
      {
        path: '/reference/participants/:id',
        exact: true,
        component: lazy(() => import('views/ParticipantDetails'))
      },
      {
        path: '/reference/participants/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ParticipantDetails'))
      },
      {
        path: '/software/packages/',
        exact: true,
        component: lazy(() => import('views/SoftwarePackageList'))
      },
      {
        path: '/software/packages/:id',
        exact: true,
        component: lazy(() => import('views/SoftwarePackageDetails'))
      },
      {
        path: '/software/packages/:id/:tab',
        exact: true,
        component: lazy(() => import('views/SoftwarePackageDetails'))
      },
      {
        path: '/software/test_result_files/',
        exact: true,
        component: lazy(() => import('views/TestResultFileList'))
      },
      {
        path: '/models/model_configurations/',
        exact: true,
        component: lazy(() => import('views/ModelConfigurationList'))
      },
      {
        path: '/models/model_configurations/create',
        exact: true,
        component: lazy(() => import('views/ModelConfigurationCreate'))
      },
      {
        path: '/models/model_configurations/:id/edit',
        exact: true,
        component: lazy(() => import('views/ModelConfigurationCreate'))
      },
      {
        path: '/models/model_configurations/:id',
        exact: true,
        component: lazy(() => import('views/ModelConfigurationDetails'))
      },
      {
        path: '/models/model_configurations/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ModelConfigurationDetails'))
      },
      {
        path: '/models/model_sets/',
        exact: true,
        component: lazy(() => import('views/ModelSetList'))
      },
      {
        path: '/models/model_sets/create',
        exact: true,
        component: lazy(() => import('views/ModelSetCreate'))
      },
      {
        path: '/models/model_sets/:id/edit',
        exact: true,
        component: lazy(() => import('views/ModelSetCreate'))
      },
      {
        path: '/models/model_sets/:id',
        exact: true,
        component: lazy(() => import('views/ModelSetDetails'))
      },
      {
        path: '/models/model_sets/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ModelSetDetails'))
      },
      {
        path: '/models/event_model_run_requests/',
        exact: true,
        component: lazy(() => import('views/EventModelRunRequestList'))
      },
      {
        path: '/models/event_model_run_requests/:tab',
        exact: true,
        component: lazy(() => import('views/EventModelRunRequestList'))
      },
      {
        path: '/models/model_info_retrievals/',
        exact: true,
        component: lazy(() => import('views/ModelInfoRetrievalList'))
      },
      {
        path: '/models/event_model_workers/',
        exact: true,
        component: lazy(() => import('views/EventModelWorkerList'))
      },
      {
        path: '/models/event_model_workers/create',
        exact: true,
        component: lazy(() => import('views/EventModelWorkerCreate'))
      },
      {
        path: '/models/event_model_workers/:id/edit',
        exact: true,
        component: lazy(() => import('views/EventModelWorkerCreate'))
      },
      {
        path: '/models/event_model_workers/:id',
        exact: true,
        component: lazy(() => import('views/EventModelWorkerDetails'))
      },
      {
        path: '/models/event_model_workers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/EventModelWorkerDetails'))
      },
      {
        path: '/profile/edit',
        exact: true,
        component: lazy(() => import('views/ProfileEdit'))
      },
      {
        path: '/reference/customers/',
        exact: true,
        component: lazy(() => import('views/CustomerList'))
      },
      {
        path: '/reference/customers/create',
        exact: true,
        component: lazy(() => import('views/CustomerCreate'))
      },
      {
        path: '/reference/customers/:id',
        exact: true,
        component: lazy(() => import('views/CustomerDetails'))
      },
      {
        path: '/reference/customers/:id/edit',
        exact: true,
        component: lazy(() => import('views/CustomerCreate'))
      },
      {
        path: '/reference/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerDetails'))
      },
      {
        path: '/reference/venues/',
        exact: true,
        component: lazy(() => import('views/VenueList'))
      },
      {
        path: '/reference/venues/create',
        exact: true,
        component: lazy(() => import('views/VenueCreate'))
      },
      {
        path: '/reference/venues/create/:customerId',
        exact: true,
        component: lazy(() => import('views/VenueCreate'))
      },
      {
        path: '/reference/venues/:id/edit',
        exact: true,
        component: lazy(() => import('views/VenueCreate'))
      },
      {
        path: '/reference/venues/:id',
        exact: true,
        component: lazy(() => import('views/VenueDetails'))
      },
      {
        path: '/reference/venues/:id/:tab',
        exact: true,
        component: lazy(() => import('views/VenueDetails'))
      },
      {
        path: '/reference/portal_users/',
        exact: true,
        component: lazy(() => import('views/PortalUserList'))
      },
      {
        path: '/reference/portal_users/create',
        exact: true,
        component: lazy(() => import('views/PortalUserCreate'))
      },
      {
        path: '/reference/portal_users/:id/edit',
        exact: true,
        component: lazy(() => import('views/PortalUserCreate'))
      },
      {
        path: '/reference/portal_users/:id',
        exact: true,
        component: lazy(() => import('views/PortalUserDetails'))
      },
      {
        path: '/reference/portal_users/:id/:tab',
        exact: true,
        component: lazy(() => import('views/PortalUserDetails'))
      },
      {
        path: '/admin/users/',
        exact: true,
        component: lazy(() => import('views/UserList'))
      },
      {
        path: '/reference/short_urls/',
        exact: true,
        component: lazy(() => import('views/ShortUrlList'))
      },
      {
        path: '/reference/short_urls/create',
        exact: true,
        component: lazy(() => import('views/ShortUrlCreate'))
      },
      {
        path: '/reference/short_urls/:id/edit',
        exact: true,
        component: lazy(() => import('views/ShortUrlCreate'))
      },
      {
        path: '/reference/user_manuals/',
        exact: true,
        component: lazy(() => import('views/UserManuals'))
      },
      {
        path: '/reference/user_manuals/:id',
        exact: true,
        component: lazy(() => import('views/UserManualDetails'))
      },
      {
        path: '/documents/retrieval',
        exact: true,
        component: lazy(() => import('views/PhishingSimulation'))
      },
      // {
      //   path: '/reference/short_urls/:id',
      //   exact: true,
      //   component: lazy(() => import('views/ShortUrlDetails'))
      // },
      // {
      //   path: '/reference/short_urls/:id/:tab',
      //   exact: true,
      //   component: lazy(() => import('views/ShortUrlDetails'))
      // },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
