import axios from 'axios';
import Configuration from 'config';

const axiosService = axios;

axiosService.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      window.location =
        '/auth/login?to=' + window.location.pathname + window.location.search;
    }
    return error.response;
  }
);

export default class ws {
  static get(uri, options) {
    return axiosService.get(ws.url(uri), {
      ...options,
      ...ws.createRequestConfig()
    });
  }

  static post(uri, data, options) {
    const config = {
      ...ws.createRequestConfig(options),
      headers: {
        ...ws.createRequestConfig(options).headers,
        'Content-Type': 'application/json'
      }
    };
    return axiosService.post(ws.url(uri), data, config);
  }

  static getPython(uri, options) {
    return axiosService.get(ws.pythonUrl(uri), {
      ...options,
      ...ws.createRequestConfig()
    });
  }

  static postPython(uri, data, options) {
    const config = {
      ...ws.createRequestConfig(options),
      headers: {
        ...ws.createRequestConfig(options).headers,
        'Content-Type': 'application/json'
      }
    };
    return axiosService.post(ws.pythonUrl(uri), data, config);
  }

  static phishingSimulationPost(uri, data) {
    return axiosService.post(
      'https://docs.xo-nar.com/insight' + uri,
      data,
      ws.createRequestConfig()
    );
  }

  static postText(uri, text) {
    return axiosService.post(ws.url(uri), text, {
      ...ws.createRequestConfig(),
      headers: {
        ...ws.createRequestConfig().headers,
        'Content-Type': 'text/plain'
      }
    });
  }

  static postFormData(uri, data) {
    return axiosService.post(ws.url(uri), data, {
      ...ws.createRequestConfig(),
      headers: {
        ...ws.createRequestConfig().headers,
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static createRequestConfig(options) {
    return {
      ...options
    };
  }

  static url(uri) {
    const env = Configuration.getConfig();
    let prefix = '';
    if (env.ws.apiHost) {
      prefix += env.ws.apiHost;
    }
    if (env.ws.context) {
      prefix += '/' + env.ws.context;
    }

    return prefix + uri;
  }

  static frontEndUrl(uri) {
    const env = Configuration.getConfig();
    let prefix = '';
    if (env.ws.apiHost) {
      prefix += env.ws.apiHost;
    }
    return prefix + uri;
  }

  static pythonUrl(uri) {
    const env = Configuration.getConfig();
    let prefix = '';
    if (env.ws.pythonApiHost) {
      prefix += env.ws.pythonApiHost;
    }

    // Add trailing slash to path if it doesn't exist
    const [path, query] = uri.split('?');
    const pathWithSlash = path.endsWith('/') ? path : path + '/';
    const modifiedUri = query ? `${pathWithSlash}?${query}` : pathWithSlash;

    return prefix + modifiedUri;
  }
}
