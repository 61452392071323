import * as actionTypes from 'actions';
import { PORTAL_USER_ROLES } from 'common/constants';

const initialState = {
  saved: false,
  savedPortalUser: {},
  deleted: false,
  deletedPortalUser: {},
  portalUser: {
    creationId: null,
    name: '',
    emailAddress: '',
    customers: [],
    roles: ['STAFF'],
    sendWelcomeEmail: true
  },
  customers: [],
  validationMessages: {}
};

const portalUserCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PORTAL_USER_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedParticipant: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages
      };
    }

    case actionTypes.DELETE_PORTAL_USER_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedParticipant: action.payload.data.result
      };
    }

    case actionTypes.EDIT_PORTAL_USER_FULFILLED: {
      return {
        ...state,
        portalUser: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_PORTAL_USER_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_PORTAL_USER_VALUE_UPDATE: {
      return {
        ...state,
        portalUser: {
          ...state.portalUser,
          ...action.payload
        }
      };
    }

    case actionTypes.SET_PORTAL_USER_ROLE: {
      const { role, roleGroup } = action.payload;

      // If this is an optional roleGroup, deselect the clicked role (if index > -1)

      const roles = state.portalUser.roles
        ? state.portalUser.roles.slice()
        : [];

      let roleRemoved = false;
      if (roleGroup !== 'General Access') {
        const roleIndex = roles.indexOf(role);
        console.log('Role Index');
        if (roleIndex > -1) {
          roles.splice(roleIndex, 1);
          roleRemoved = true;
        }
      }

      if (!roleRemoved) {
        const existingRoleGroupRoleIndex = roles
          .map(r => PORTAL_USER_ROLES[r].roleGroup)
          .indexOf(roleGroup);
        if (existingRoleGroupRoleIndex > -1) {
          roles.splice(existingRoleGroupRoleIndex, 1);
        }
        roles.push(role);
      }

      return {
        ...state,
        portalUser: {
          ...state.portalUser,
          roles: roles
        }
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
};

export default portalUserCreateReducer;
