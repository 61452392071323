import { colors } from '@material-ui/core';

// https://experience.sap.com/fiori-design-web/color-palettes/

export const TEAMVIEWER_START_URL = 'https://start.teamviewer.com/';

export const XONAR_EMAIL_DOMAIN_SUFFIX = '@xonar.com';

export const KIOSK_PATHS = [
  '/training/cells_overview/',
  '/deployment/cells_map',
  '/events/calendar/?kiosk=true'
];

export const CHART_COLORS = [
  '#D3219B',
  '#1C55F2',
  '#D78532',
  '#08102A',
  '#945ECF',
  '#13A4B4',
  '#525EF4',
  '#BF399E',
  '#6C8893',
  '#EE6868',
  '#ED4A7B'
];

export const TIME_ZONES = [
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York'
];

export const EVENT_SETTING_COMMIT_HASH = 'codeBaseCommitHash';

export const GIT_COMMIT_URL =
  'https://bitbucket.org/xonarllc/xonar_core/commits/';

export const GIT_COMMIT_URLS = {
  'xonar-cell-os-apt': 'https://bitbucket.org/xonarllc/cell_setup/commits/',
  'xonar-cell-setup': 'https://bitbucket.org/xonarllc/cell_setup/commits/',
  'xonar-mqtt-tls': 'https://bitbucket.org/xonarllc/mqtt-tls/commits/',
  'xonartechnologies/cell_software-dev':
    'https://bitbucket.org/xonarllc/xonar_core/commits/',
  'xonartechnologies/cell_software-uat':
    'https://bitbucket.org/xonarllc/xonar_core/commits/',
  'xonartechnologies/cell_software-prod':
    'https://bitbucket.org/xonarllc/xonar_core/commits/',
  'xonar-cell-software': 'https://bitbucket.org/xonarllc/xonar_core/commits/',
  'xonar-cell-software-docker':
    'https://bitbucket.org/xonarllc/xonar_core/commits/',
  'xonar-operator-middleware':
    'https://bitbucket.org/xonarllc/operatormiddleware/commits/'
};
export const SERVICE_POLLING_FREQ = 1000;

export const EVENT_DATA_SET_TYPES = {
  MODEL_DATA: 'Model Data',
  WALK_ANALYSIS: 'Walk Analysis'
};

export const SESSION_GROUP_ASSOCIATION_TYPES = {
  COMBINED: 'All Walks',
  OBJECT: 'Object Walks Only',
  NO_OBJECT: 'No Object Walks Only',
  FEMALE_COMBINED: 'Female All Walks',
  FEMALE_OBJECT: 'Female Object Walks Only',
  FEMALE_NO_OBJECT: 'Female No Object Walks Only',
  MALE_COMBINED: 'Male All Walks',
  MALE_OBJECT: 'Male Object Walks Only',
  MALE_NO_OBJECT: 'Male No Object Walks Only'
};

export const DEFAULT_SUB_MODEL_AREA_DESCRIPTIONS = ['Torso', 'Ankle'];

export const SESSION_DURATIONS = [
  { mins: 15, label: '15 Mins' },
  { mins: 30, label: '30 Mins' },
  { mins: 45, label: '45 Mins' },
  { mins: 60, label: '1 Hr' },
  { mins: 75, label: '1 1/4 Hrs' },
  { mins: 90, label: '1 1/2 Hrs' },
  { mins: 105, label: '1 3/4 Hrs' },
  { mins: 120, label: '2 Hrs' }
];

export const EVENT_DATA_SET_WALK_DOWNLOAD_SIZE_LIMIT = 1000;

export const RADAR_STANCHION_LOCATIONS = {
  LEFT_ENTRY: 'Left Entry',
  LEFT_EXIT: 'Left Exit',
  RIGHT_ENTRY: 'Right Entry',
  RIGHT_EXIT: 'Right Exit'
};

export const RADAR_POSITIONS = {
  TOP: 'Top',
  MIDDLE: 'Middle',
  BOTTOM: 'Bottom',
  ANKLE: 'Ankle'
};

export const RADAR_STATUSES = {
  ACTIVE: 'Active',
  DECOMMISSIONED: 'Decomissioned'
};

export const RADAR_PLACEMENTS = {
  LEFT_RIGHT_3UP: 'Left & Right - 3UP',
  TWO_BY_TWO_3UP: '2x2 - 3UP',
  TWO_BY_TWO_4UP: '2x2 - 4UP',
  ANKLE_ONLY: 'Ankle Only'
};

export const RUNWAY_WIDTHS = {
  ULTRA_NARROW: 'Ultra Narrow',
  WIDE: 'Wide',
  NARROW: 'Narrow'
};

export const CELL_BUILD_COMPONENT_STATUSES = {
  PENDING_INSTALLATION: 'Pending Installation',
  INSTALLATION_STARTED: 'Installation Started',
  INSTALLATION_COMPLETED: 'Installation Completed',
  INSTALLATION_APPROVED: 'Installation Approved',
  INSTALLATION_TESTED: 'Installation Tested'
};

export const SOFTWARE_PACKAGE_REPORT_STATUSES = {
  INITIAL: 'Initial',
  NO_CHANGE: 'No Change',
  CHANGE: 'Change'
};

export const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const STATES_CENTERS = [
  { name: 'Alabama', coordinates: [-86.8287, 32.7794] },
  { name: 'Alaska', coordinates: [-152.2782, 64.0685] },
  { name: 'Arizona', coordinates: [-111.6602, 34.2744] },
  { name: 'Arkansas', coordinates: [-92.4426, 34.8938] },
  { name: 'California', coordinates: [-119.4696, 37.1841] },
  { name: 'Colorado', coordinates: [-105.5478, 38.9972] },
  { name: 'Connecticut', coordinates: [-72.7273, 41.6219] },
  { name: 'Delaware', coordinates: [-75.505, 38.9896] },
  { name: 'District of Columbia', coordinates: [-77.0147, 38.9101] },
  { name: 'Florida', coordinates: [-82.4497, 28.6305] },
  { name: 'Georgia', coordinates: [-83.4426, 32.6415] },
  { name: 'Hawaii', coordinates: [-156.3737, 20.2927] },
  { name: 'Idaho', coordinates: [-114.613, 44.3509] },
  { name: 'Illinois', coordinates: [-89.1965, 40.0417] },
  { name: 'Indiana', coordinates: [-86.2816, 39.8942] },
  { name: 'Iowa', coordinates: [-93.496, 42.0751] },
  { name: 'Kansas', coordinates: [-98.3804, 38.4937] },
  { name: 'Kentucky', coordinates: [-85.3021, 37.5347] },
  { name: 'Louisiana', coordinates: [-91.9968, 31.0689] },
  { name: 'Maine', coordinates: [-69.2428, 45.3695] },
  { name: 'Maryland', coordinates: [-76.7909, 39.055] },
  { name: 'Massachusetts', coordinates: [-71.8083, 42.2596] },
  { name: 'Michigan', coordinates: [-85.4102, 44.3467] },
  { name: 'Minnesota', coordinates: [-94.3053, 46.2807] },
  { name: 'Mississippi', coordinates: [-89.6678, 32.7364] },
  { name: 'Missouri', coordinates: [-92.458, 38.3566] },
  { name: 'Montana', coordinates: [-109.6333, 47.0527] },
  { name: 'Nebraska', coordinates: [-99.7951, 41.5378] },
  { name: 'Nevada', coordinates: [-116.6312, 39.3289] },
  { name: 'New Hampshire', coordinates: [-71.5811, 43.6805] },
  { name: 'New Jersey', coordinates: [-74.6728, 40.1907] },
  { name: 'New Mexico', coordinates: [-106.1126, 34.4071] },
  { name: 'New York', coordinates: [-75.5268, 42.9538] },
  { name: 'North Carolina', coordinates: [-79.3877, 35.5557] },
  { name: 'North Dakota', coordinates: [-100.4659, 47.4501] },
  { name: 'Ohio', coordinates: [-82.7937, 40.2862] },
  { name: 'Oklahoma', coordinates: [-97.4943, 35.5889] },
  { name: 'Oregon', coordinates: [-120.5583, 43.9336] },
  { name: 'Pennsylvania', coordinates: [-77.7996, 40.8781] },
  { name: 'Rhode Island', coordinates: [-71.5562, 41.6762] },
  { name: 'South Carolina', coordinates: [-80.8964, 33.9169] },
  { name: 'South Dakota', coordinates: [-100.2263, 44.4443] },
  { name: 'Tennessee', coordinates: [-86.3505, 35.858] },
  { name: 'Texas', coordinates: [-99.3312, 31.4757] },
  { name: 'Utah', coordinates: [-111.6703, 39.3055] },
  { name: 'Vermont', coordinates: [-72.6658, 44.0687] },
  { name: 'Virginia', coordinates: [-78.8537, 37.5215] },
  { name: 'Washington', coordinates: [-120.4472, 47.3826] },
  { name: 'West Virginia', coordinates: [-80.6227, 38.6409] },
  { name: 'Wisconsin', coordinates: [-89.9941, 44.6243] },
  { name: 'Wyoming', coordinates: [-107.5512, 42.9957] }
];

export const CELL_MODELS = {
  V4_1: 'V4.1',
  V5_0: 'V5.0',
  V5_1: 'V5.1',
  V5_2: 'V5.2',
  V5_3: 'V5.3'
};

export const DEPRECATED_CELL_MODELS = [
  'V4',
  'MVP_4',
  'MVP_3_COMPUTER_IN_STANCHION',
  'MVP_3',
  'MVP_2',
  'MVP_1',
  'GREEN_STANCHION',
  'V3',
  'V3_LT102',
  'V3_ANKLE_ONLY',
  'V3_16_RADARS',
  'V3_7_0',
  'V3_8_TOF',
  'V3_9_0',
  'V4_0',
  'V4_2'
];

export const MALE_BMI_CATEGORIES = [
  '≤23',
  '>23 ≤26 ',
  '>26 ≤30',
  '>30 ≤34',
  '>34'
];

export const FEMALE_BMI_CATEGORIES = [
  '≤23',
  '>23 ≤25 ',
  '>25 ≤30',
  '>30 ≤35',
  '>35'
];

export const MALE_HEIGHT_CATEGORIES = [
  '≤66"',
  '>66" ≤68" ',
  '>68" ≤70"',
  '>70" ≤72"',
  '>72"'
];

export const FEMALE_HEIGHT_CATEGORIES = [
  '≤61"',
  '>61" ≤63" ',
  '>63" ≤65"',
  '>65" ≤67"',
  '>67"'
];

export const USER_ROLES = {
  superAdmin: 'Super Admin',
  customerAdmin: 'Customer Admin',
  dataValidator: 'Data Validator',
  dataCollector: 'Data Collector',
  dataAuditor: 'Data Auditor'
};

export const PORTAL_USER_ROLES = {
  STAFF: {
    name: 'Staff',
    description: 'Read only access to events, devices, and venues',
    roleGroup: 'General Access'
  },
  SUPERVISOR: {
    name: 'Supervisor',
    description: 'Staff level access plus the ability to edit events',
    roleGroup: 'General Access'
  },
  ADMIN: {
    name: 'Admin',
    description:
      'Supervisor access plus the ability to edit venues, change inference levels, change device operation modes (e.g. testing), change device names',
    roleGroup: 'General Access'
  },
  FACIALRECOGNITION: {
    name: 'Face Recognition',
    description: 'View people added for face recognition',
    roleGroup: 'Face Recognition'
  },
  FACIALRECOGNITIONADMIN: {
    name: 'Face Recognition Admin',
    description: 'View, edit and remove people for face recognition',
    roleGroup: 'Face Recognition'
  }
};

export const CLOTHING_TYPES = {
  LIGHT: 'Light',
  MEDIUM: 'Medium',
  HEAVY: 'Heavy'
};

export const POCKET_LOCATIONS = {
  FRONT: 'Front',
  BACK: 'Back'
};

export const SUBJECT_AREAS = {
  RIGHT_SIDE: 'Right Side',
  LEFT_SIDE: 'Left Side',
  RIGHT_CHEST: 'Right Chest',
  LEFT_CHEST: 'Left Chest',
  RIGHT_FOOT: 'Right Ankle',
  LEFT_FOOT: 'Left Ankle',
  HEAD: 'Head',
  BACK: 'Back',
  FRONT: 'Front',
  BAG: 'Bag',
  BAG_LEFT_SHOULDER_HIGH: 'Bag - Left Shoulder High',
  BAG_LEFT_SHOULDER_LOW: 'Bag - Left Shoulder Low',
  BAG_RIGHT_SHOULDER_HIGH: 'Bag - Right Shoulder High',
  BAG_RIGHT_SHOULDER_LOW: 'Bag - Right Shoulder Low',
  BAG_LEFT_HAND: 'Bag - Left Hand',
  BAG_RIGHT_HAND: 'Bag - Right Hand',
  BAG_BACK: 'Bag - Back',
  BAG_FANNYPACK: 'Bag - Fanny Pack',
  LEFT_ANKLE_INSIDE: 'Left Ankle - Inside',
  LEFT_ANKLE_OUTSIDE: 'Left Ankle - Outside',
  RIGHT_ANKLE_INSIDE: 'Right Ankle - Inside',
  RIGHT_ANKLE_OUTSIDE: 'Right Ankle - Outside',
  LEFT_BACK_WAISTBAND: 'Waistband - Left Side, Back',
  LEFT_FRONT_WAISTBAND: 'Waistband - Left Side, Front',
  RIGHT_BACK_WAISTBAND: 'Waistband - Right Side, Back',
  RIGHT_FRONT_WAISTBAND: 'Waistband - Right Side, Front',
  RIGHT_THIGH_OUTSIDE: 'Thigh - Right Side, Outside',
  LEFT_THIGH_OUTSIDE: 'Thigh - Left Side, Outside',
  UNDER_BRA: 'Under Bra'
};

export const SUBJECT_AREA_GROUPS = {
  TORSO: 'Torso',
  ANKLE: 'Ankle'
};

export const RADAR_FPS = {
  FPS_80: '80 Hz',
  FPS_40: '40 Hz',
  FPS_20: '20 Hz'
};

export const POCKET_ITEMS_SUBJECT_AREAS = [
  SUBJECT_AREAS.RIGHT_SIDE,
  SUBJECT_AREAS.LEFT_SIDE
];

export const MODEL_ANALYSIS_SESSION_SOURCES = {
  CUSTOMER_EVENT: 'CUSTOMER_EVENT',
  SESSION: 'SESSION',
  SESSION_GROUP: 'SESSION_GROUP',
  SETUP_SCENARIO: 'SETUP_SCENARIO',
  EVENT_DATA_SET: 'EVENT_DATA_SET'
};

export const SCENARIO_CATEGORIES = {
  USER: 'User',
  REFERENCE: 'Reference'
};

export const VENUE_TYPES = {
  CONCERT_HALL: 'Concert Hall',
  STADIUM: 'Stadium',
  THEATRE: 'Theatre',
  THEME_PARK: 'Theme Park',
  OFFICE: 'Office',
  RESTAURANT: 'Restaurant',
  HOTEL: 'Hotel',
  BAR: 'Bar',
  CLUB: 'Club',
  CONFERENCE_CENTER: 'Conference Center',
  BUSINESS_CENTER: 'Business Center',
  COMMUNITY_CENTER: 'Community Center',
  ACADEMIC: 'Academic',
  ART_GALLERY: 'Art Galery'
};

export const MODEL_CONFIGURATION_STATUSES = {
  ACTIVE: 'Active',
  DEPRECATED: 'Deprecated'
};

export const MODEL_CONFIGURATION_EVENT_MODEL_WORKER_STATUSES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PAUSED: 'Paused',
  EXCLUDED: 'Excluded'
};

export const MODEL_RUN_REQUEST_STATUSES = {
  QUEUED: 'Queued',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  ERRORED: 'Error'
};

export const SESSION_GROUP_CATEGORIES = {
  USER: 'User',
  REFERENCE: 'Reference'
};

export const OBJECT_TYPE_CATEGORIES = {
  NON_WEAPON: {
    name: 'Non Weapon'
  },
  GUN: {
    name: 'Gun',
    objectOfInterest: true
  },
  KNIFE: {
    name: 'Knife'
  }
};

export const ENTRY_FEET = {
  LEFT: 'Left Foot',
  RIGHT: 'Right Foot',
  BOTH_FEET: 'Alternating Feet'
};

export const GENDERS = {
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non Binary'
};

export const ANALYSIS_BIAS_TYPES = {
  CONVENTIONAL: { resultType: 'conventionalBiasResults' },
  BREAKEVEN: { resultType: 'breakevenBiasResults' }
};

export const ANALISYS_OVERVIEW_RESULT_TYPES = {
  TORSO_OBJECT_PRESENT: {
    name: 'Torso Object Present',
    chartColors: [CHART_COLORS[0], CHART_COLORS[1], CHART_COLORS[2]]
  },
  TORSO_NO_OBJECT_PRESENT: {
    name: 'Torso No Object Present',
    chartColors: [CHART_COLORS[0], CHART_COLORS[1], CHART_COLORS[2]]
  },
  TORSO_SUM_OBJECT_PRESENT: {
    name: 'Torso Sum',
    separator: true,
    chartColors: [CHART_COLORS[0], CHART_COLORS[1], CHART_COLORS[2]]
  },

  ANKLE_OBJECT_PRESENT: {
    name: 'Ankle Object Present',
    chartColors: [CHART_COLORS[0], CHART_COLORS[1], CHART_COLORS[2]]
  },
  ANKLE_NO_OBJECT_PRESENT: {
    name: 'Ankle No Object Present',
    chartColors: [CHART_COLORS[0], CHART_COLORS[1], CHART_COLORS[2]]
  },
  ANKLE_SUM_OBJECT_PRESENT: {
    name: 'Ankle Sum',
    separator: true,
    chartColors: [CHART_COLORS[0], CHART_COLORS[1], CHART_COLORS[2]]
  },

  OBJECT_PRESENT: {
    name: 'Overall Object Present',
    chartColors: [CHART_COLORS[0], CHART_COLORS[1], CHART_COLORS[2]]
  },
  NO_OBJECT_PRESENT: {
    name: 'Overall No Object',
    chartColors: [CHART_COLORS[3], CHART_COLORS[4], CHART_COLORS[5]]
  },
  SUM_OBJECT_PRESENT: {
    name: 'Overall Sum',
    separator: true,
    chartHidden: false,
    difference: true
  },

  BREAKEVEN_BIAS: {
    tableHidden: true,
    name: 'Breakeven Sensitivity',
    separator: true,
    chartHidden: false,
    chartColors: [CHART_COLORS[9], CHART_COLORS[10], CHART_COLORS[11]]
  },

  LEFT_STANCHIONS: {
    name: 'Left Stanchions',
    chartColors: [CHART_COLORS[12], CHART_COLORS[13], CHART_COLORS[14]]
  },
  RIGHT_STANCHIONS: {
    name: 'Right Stanchions',
    separator: true,
    chartColors: [CHART_COLORS[15], CHART_COLORS[16], CHART_COLORS[17]]
  },
  // DIFFERENCE_STANCHIONS: {name: 'Difference/Sum', separator: true, chartHidden: true, difference: true},

  // LEFT_FOOT_ENTRY: {name: 'Left Foot Entry', footEntry: true, chartColors: [CHART_COLORS[18], CHART_COLORS[19], CHART_COLORS[20]]},
  // RIGHT_FOOT_ENTRY: {name: 'Right Foot Entry', footEntry: true, chartColors: [CHART_COLORS[21], CHART_COLORS[22], CHART_COLORS[23]]},
  // DIFFERENCE_FOOT_ENTRY: {name: 'Difference/Sum', footEntry: true, separator: true, chartHidden: true, difference: true},

  // LEFT_FOOT_ENTRY_RIGHT_SIDE_OBJECT: {name: 'Left Foot Entry, Right Side Object', footEntry: true, chartColors: [CHART_COLORS[24], CHART_COLORS[25], CHART_COLORS[26]]},
  // LEFT_FOOT_ENTRY_LEFT_SIDE_OBJECT: {name: 'Left Foot Entry, Left Side Object', footEntry: true, chartColors: [CHART_COLORS[30], CHART_COLORS[31], CHART_COLORS[32]]},
  // LEFT_FOOT_ENTRY_DIFFERENCE: {name: 'Difference/Sum', footEntry: true, separator: true, chartHidden: true, difference: true},

  // RIGHT_FOOT_ENTRY_RIGHT_SIDE_OBJECT: {name: 'Right Foot Entry, Right Side Object', footEntry: true, chartColors: [CHART_COLORS[27], CHART_COLORS[28], CHART_COLORS[29]]},
  // RIGHT_FOOT_ENTRY_LEFT_SIDE_OBJECT: {name: 'Right Foot Entry, Left Side Object', footEntry: true, chartColors: [CHART_COLORS[33], CHART_COLORS[34], CHART_COLORS[35]]},
  // RIGHT_FOOT_ENTRY_DIFFERENCE: {name: 'Difference/Sum', footEntry: true, separator: true, chartHidden: true, difference: true},

  // LEFT_SIDE_OBJECT_RIGHT_FOOT_ENTRY: {name: 'Left Side Object, Right Foot Entry', footEntry: true, chartColors: [CHART_COLORS[27], CHART_COLORS[28], CHART_COLORS[29]]},
  // LEFT_SIDE_OBJECT_LEFT_FOOT_ENTRY: {name: 'Left Side Object, Left Foot Entry', footEntry: true, chartColors: [CHART_COLORS[33], CHART_COLORS[34], CHART_COLORS[35]]},
  // LEFT_SIDE_OBJECT_DIFFERENCE: {name: 'Difference/Sum', footEntry: true, separator: true, chartHidden: true, difference: true},

  // RIGHT_SIDE_OBJECT_RIGHT_FOOT_ENTRY: {name: 'Right Side Object, Right Foot Entry', footEntry: true, chartColors: [CHART_COLORS[27], CHART_COLORS[28], CHART_COLORS[29]]},
  // RIGHT_SIDE_OBJECT_LEFT_FOOT_ENTRY: {name: 'Right Side Object, Left Foot Entry', footEntry: true, chartColors: [CHART_COLORS[33], CHART_COLORS[34], CHART_COLORS[35]]},
  // RIGHT_SIDE_OBJECT_DIFFERENCE: {name: 'Difference/Sum', footEntry: true, chartHidden: true, difference: true}

  OBJECT_PRESENT_HEAD: { name: 'Location - Head', hideEmpty: true },
  OBJECT_PRESENT_RIGHT_CHEST: {
    name: 'Location - Right Chest',
    hideEmpty: true
  },
  OBJECT_PRESENT_LEFT_CHEST: { name: 'Location - Left Chest', hideEmpty: true },
  OBJECT_PRESENT_FRONT: { name: 'Location - Front', hideEmpty: true },
  OBJECT_PRESENT_BACK: { name: 'Location - Back', hideEmpty: true },
  OBJECT_PRESENT_RIGHT_SIDE: { name: 'Location - Right Side', hideEmpty: true },
  OBJECT_PRESENT_LEFT_SIDE: { name: 'Location - Left Side', hideEmpty: true },
  OBJECT_PRESENT_RIGHT_FOOT: { name: 'Location - Right Foot', hideEmpty: true },
  OBJECT_PRESENT_LEFT_FOOT: { name: 'Location - Left Foot', hideEmpty: true },
  OBJECT_PRESENT_BAG: { name: 'Location - Bag', hideEmpty: true },
  OBJECT_PRESENT_BAG_LEFT_SHOULDER_HIGH: {
    name: 'Location - Bag - Left Shoulder High',
    hideEmpty: true
  },
  OBJECT_PRESENT_BAG_LEFT_SHOULDER_LOW: {
    name: 'Location - Bag - Left Shoulder Low',
    hideEmpty: true
  },
  OBJECT_PRESENT_BAG_RIGHT_SHOULDER_HIGH: {
    name: 'Location - Bag - Right Shoulder High',
    hideEmpty: true
  },
  OBJECT_PRESENT_BAG_RIGHT_SHOULDER_LOW: {
    name: 'Location - Bag - Right Shoulder Low',
    hideEmpty: true
  },
  OBJECT_PRESENT_BAG_LEFT_HAND: {
    name: 'Location - Bag - Left Hand',
    hideEmpty: true
  },
  OBJECT_PRESENT_BAG_RIGHT_HAND: {
    name: 'Location - Bag - Right Hand',
    hideEmpty: true
  },
  OBJECT_PRESENT_BAG_BACK: { name: 'Location - Bag - Back', hideEmpty: true },
  OBJECT_PRESENT_BAG_FANNYPACK: {
    name: 'Location - Bag - Fanny Pack',
    hideEmpty: true
  },
  OBJECT_PRESENT_LEFT_ANKLE_INSIDE: {
    name: 'Location - Left Ankle - Inside',
    hideEmpty: true
  },
  OBJECT_PRESENT_LEFT_ANKLE_OUTSIDE: {
    name: 'Location - Left Ankle - Outside',
    hideEmpty: true
  },
  OBJECT_PRESENT_RIGHT_ANKLE_INSIDE: {
    name: 'Location - Right Ankle - Inside',
    hideEmpty: true
  },
  OBJECT_PRESENT_RIGHT_ANKLE_OUTSIDE: {
    name: 'Location - Right Ankle - Outside',
    hideEmpty: true
  },
  OBJECT_PRESENT_LEFT_BACK_WAISTBAND: {
    name: 'Location - Waistband - Left Side, Back',
    hideEmpty: true
  },
  OBJECT_PRESENT_LEFT_FRONT_WAISTBAND: {
    name: 'Location - Waistband - Left Side, Front',
    hideEmpty: true
  },
  OBJECT_PRESENT_RIGHT_BACK_WAISTBAND: {
    name: 'Location - Waistband - Right Side, Back',
    hideEmpty: true
  },
  OBJECT_PRESENT_RIGHT_FRONT_WAISTBAND: {
    name: 'Location - Waistband - Right Side, Front',
    hideEmpty: true
  },
  OBJECT_PRESENT_RIGHT_THIGH_OUTSIDE: {
    name: 'Location - Thigh - Right Side, Outside',
    hideEmpty: true
  },
  OBJECT_PRESENT_LEFT_THIGH_OUTSIDE: {
    name: 'Location - Thigh - Left Side, Outside',
    hideEmpty: true
  },
  OBJECT_PRESENT_UNDER_BRA: { name: 'Location - Under Bra', hideEmpty: true }
};

export const TICKET_PRIORITIES = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High'
};

export const INFERENCE_MODES = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High'
};

export const TICKET_PIPELINES = {
  PRODUCTION_CELL_HARDWARE_ISSUE:
    'Support: Production Cell Hardware Issue (L2)',
  PRODUCTION_CELL_SOFTWARE_ISSUE:
    'Support: Production Cell Software Issue (L2)',
  PRODUCTION_CELL_HARDWARE_MAINTENANCE:
    'Support: Production Cell Hardware Maintenance (L2)',
  TRAINING_CELL_HARDWARE_ISSUE:
    'Support: Non Production Cell Hardware Issue (L2)',
  TRAINING_CELL_SOFTWARE_ISSUE:
    'Support: Non Production Cell Software Issue (L2)',
  TRAINING_CELL_HARDWARE_MAINTENANCE:
    'Support: Non Production Cell Hardware Maintenance (L2)'
};

export const PARTICIPANT_ANALISYS_OVERVIEW_RESULT_TYPES = {
  OBJECT_PRESENT: { name: 'Object Present' },
  NO_OBJECT_PRESENT: { name: 'No Object' }
};

export const ANALISYS_OVERVIEW_RESULT_MEASURES = {
  ACCURACY: 'Accuracy',
  SCORE_CORRECT_COUNT: 'Correct',
  SCORE_INCORRECT_COUNT: 'Incorrect',
  AVERAGE_MARGIN: 'Avg Margin',
  MARGIN_HIGH: 'Margin High',
  MARGIN_LOW: 'Margin Low',
  NUM_STANDARD_DEVIATIONS: 'Num Std Dev',
  STANDARD_DEVIATION: 'Std Dev',
  CROSSOVER: 'Crossover'
};

export const PARTICIPANT_ANALISYS_OVERVIEW_RESULT_MEASURES = {
  ACCURACY: 'Accuracy'
};

export const ANALYSIS_OVERVIEW_RESULT_MEASURE_DIFF_TYPES = {
  AVERAGE_MARGIN: { symbol: 'Σ' }
};

export const ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS = {};

export const ANALYSIS_OVERVIEW_RESULT_DESCRIPTIONS_FOR_REVIEW = {
  OBJECT_PRESENT: {
    ACCURACY:
      'Number of walks where an object is present on either or both sides and score >= 50% / number of walks where an object is present on either or both sides',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      'Number of walks where an object is present on either or both sides and score >= 50% / number of walks where an object is present on either or both sides [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      'Number of walks where an object is present on either or both sides and score >= 50% / number of walks where an object is present on either or both sides [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (score - 50%) for walks where an object is present on either or both sides',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of the margin (score - 50%) for walks where an object is present on either or both sides',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of the margin (score - 50%) for walks where an object is present on either or both sides',
    MARGIN_CORRECT:
      'The average of (score - 50%) for walks where an object is present on either or both sides and score >= 50%',
    MARGIN_INCORRECT:
      'The average of (score - 50%) for walks where an object is present on either or both sides present and score < 50%',
    MARGIN_HIGH:
      'The highest value of (score - 50%) for walks where an object is present on either or both sides',
    MARGIN_LOW:
      'The lowest value of (score - 50%) for walks where an object is present on either or both sides',
    CROSSOVER: 'Not applicable'
  },
  SUBJECT_TEMPERATURE: {
    ACCURACY: '',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS: '',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS: '',
    AVERAGE_MARGIN: '',
    VALUE:
      'The average subject temperature for walks where a temperature was recorded',
    STANDARD_DEVIATION: '',
    NUM_STANDARD_DEVIATIONS: '',
    MARGIN_CORRECT: '',
    MARGIN_INCORRECT: '',
    MARGIN_HIGH: '',
    MARGIN_LOW: '',
    CROSSOVER: ''
  },
  NO_OBJECT_PRESENT: {
    ACCURACY:
      'Number of walks where no object is present and score < 50% / Number of walks where no object is present',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      'Number of walks where no object is present and score < 50% / Number of walks where no object is present [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      ' [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (50% - score) for walks where no object is present',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of the margin (50% - score) for walks where no object is present',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of the margin (50% - score) for walks where no object is present',
    MARGIN_CORRECT:
      'The average of (50% - score) for walks where no object is present and score < 50%',
    MARGIN_INCORRECT:
      'The average of (50% - score) for walks where no object is present and score >= 50%',
    MARGIN_HIGH:
      'The highest value of (50% - score) for walks where no object is present',
    MARGIN_LOW:
      'The lowest value of (50% - score) for walks where no object is present',
    CROSSOVER: 'Not applicable'
  },
  LEFT_STANCHIONS: {
    ACCURACY:
      '((Number of walks where an object is present on the left side only and the left stanchion score >= 50%) + (number of walks where no object is present and the left stanchion score < 50%)) / ((number of walks where an object is present on the left side only) + (number of walks where no object is present))',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      '((Number of walks where an object is present on the left side only and the left stanchion score >= 50%) + (number of walks where no object is present and the left stanchion score < 50%)) / ((number of walks where an object is present on the left side only) + (number of walks where no object is present)) [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      '((Number of walks where an object is present on the left side only and the left stanchion score >= 50%) + (number of walks where no object is present and the left stanchion score < 50%)) / ((number of walks where an object is present on the left side only) + (number of walks where no object is present)) [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (left stanchion score - 50%) for walks where an object is present on the left side only and (50% - left stanchion score) where no object is present',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of the margin (left stanchion score - 50%) for walks where an object is present on the left side only and (50% - left stanchion score) where no object is present',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of the margin (left stanchion score - 50%) for walks where an object is present on the left side only and (50% - left stanchion score) where no object is present',
    MARGIN_CORRECT:
      'The average of (left stanchion score - 50%) for walks where an object is present on the left side only and score >= 50% and (50% - left stanchion score) where no object is present and score < 50%',
    MARGIN_INCORRECT:
      'The average of (left stanchion score - 50%) for walks where an object is present on the left side only and score < 50% and (50% - left stanchion score) where no object is present and score >= 50%',
    MARGIN_HIGH:
      'The highest value of (left stanchion score - 50%) for walks where an object is present on the left side only and (50% - left stanchion score) where no object is present',
    MARGIN_LOW:
      'The lowest value of (left stanchion score - 50%) for walks where an object is present on the left side only and (50% - left stanchion score) where no object is present',
    CROSSOVER:
      'The number of walks where an object is present on the left side only and the right stanchion score is greater than the left stanchion score'
  },
  RIGHT_STANCHIONS: {
    ACCURACY:
      '((Number of walks where an object is present on the right side only and the right stanchion score >= 50%) + (number of walks where no object is present and the right stanchion score < 50%)) / ((number of walks where an object is present on the right side only) + (number of walks where no object is present))',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      ' [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      ' [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (right stanchion score - 50%) for walks where an object is present on the right side only and (50% - right stanchion score) where no object is present',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of the margin (right stanchion score - 50%) for walks where an object is present on the right side only and (50% - right stanchion score) where no object is present',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of the margin (right stanchion score - 50%) for walks where an object is present on the right side only and (50% - right stanchion score) where no object is present',
    MARGIN_CORRECT:
      'The average of (right stanchion score - 50%) for walks where an object is present on the right side only and score >= 50% and (50% - right stanchion score) where no object is present and score < 50%',
    MARGIN_INCORRECT:
      'The average of (right stanchion score - 50%) for walks where an object is present on the right side only and score < 50% and (50% - right stanchion score) where no object is present and score >= 50%',
    MARGIN_HIGH:
      'The highest value of (right stanchion score - 50%) for walks where an object is present on the right side only and (50% - right stanchion score) where no object is present',
    MARGIN_LOW:
      'The lowest value of (right stanchion score - 50%) for walks where an object is present on the right side only and (50% - right stanchion score) where no object is present',
    CROSSOVER:
      'The number of walks where object is present only on the right side and the left stanchion score is greater than the right stanchion score'
  },
  LEFT_FOOT_ENTRY: {
    ACCURACY:
      '(Number of left foot entry walks where an object is present on either or both sides and the score >= 50%) + (number of left foot entry walks where no object is present on either or both sides and the score < 50%) / number of left foot entry walks',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      ' [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      ' [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (score - 50%) for left foot entry walks where and object is present on either or both sides and (50% - score) for left foot entry walks where no object is present on either or both sides',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of the margin (score - 50%) for left foot entry walks where and object is present on either or both sides and (50% - score) for left foot entry walks where no object is present on either or both sides',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of the margin (score - 50%) for left foot entry walks where and object is present on either or both sides and (50% - score) for left foot entry walks where no object is present on either or both sides',
    MARGIN_CORRECT:
      'The average of (left stanchion score - 50%) for left foot entry walks where an object is present on either or both sides and the score >= 50% and (50% - score) for left foot entry walks where no object is present and the score < 50%',
    MARGIN_INCORRECT:
      'The average of (left stanchion score - 50%) for left foot entry walks where an object is present on either or both sides and the score < 50% and (50% - score) for left foot entry walks where no object is present and the score >= 50%',
    MARGIN_HIGH:
      'The highest value of (score - 50%) for left foot entry walks where and object is present on either or both sides and (50% - score) for left foot entry walks where no object is present on either or both sides',
    MARGIN_LOW:
      'The lowest value of (score - 50%) for left foot entry walks where and object is present on either or both sides and (50% - score) for left foot entry walks where no object is present on either or both sides',
    CROSSOVER:
      'The number of left foot entry walks where an object is present only on the right side and the left stanchion score is greater than the right stanchion score or an object is present only on the left side and the right stanchion score is greater than the left stanchion score'
  },
  RIGHT_FOOT_ENTRY: {
    ACCURACY:
      '(Number of right foot entry walks where an object is present on either or both sides and the score >= 50%) + (number of right foot entry walks where no object is present on either or both sides and the score < 50%) / number of right foot entry walks',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      ' [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      ' [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (score - 50%) for right foot entry walks where and object is present on either or both sides and (50% - score) for right foot entry walks where no object is present on either or both sides',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of the margin (score - 50%) for right foot entry walks where and object is present on either or both sides and (50% - score) for right foot entry walks where no object is present on either or both sides',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of the margin (score - 50%) for right foot entry walks where and object is present on either or both sides and (50% - score) for right foot entry walks where no object is present on either or both sides',
    MARGIN_CORRECT:
      'The average of (left stanchion score - 50%) for right foot entry walks where an object is present on either or both sides and the score >= 50% and (50% - score) for right foot entry walks where no object is present and the score < 50%',
    MARGIN_INCORRECT:
      'The average of (left stanchion score - 50%) for right foot entry walks where an object is present on either or both sides and the score < 50% and (50% - score) for right foot entry walks where no object is present and the score >= 50%',
    MARGIN_HIGH:
      'The highest value of (score - 50%) for right foot entry walks where and object is present on either or both sides and (50% - score) for right foot entry walks where no object is present on either or both sides',
    MARGIN_LOW:
      'The lowest value of (score - 50%) for right foot entry walks where and object is present on either or both sides and (50% - score) for right foot entry walks where no object is present on either or both sides',
    CROSSOVER:
      'The number of right foot entry walks where an object is present only on the right side and the left stanchion score is greater than the right stanchion score or an object is present only on the left side and the right stanchion score is greater than the left stanchion score'
  },
  LEFT_FOOT_ENTRY_RIGHT_SIDE_OBJECT: {
    ACCURACY:
      '(Number of left foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of left foot entry walks where an object is present on the right side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      ' [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      ' [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    MARGIN_CORRECT:
      'The average of (right stanchion score - 50%) for left foot entry walks where object is present on the right side and the right stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (right stanchion score - 50%) for left foot entry walks where object is present on the right side and the right stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    MARGIN_LOW:
      'The lowest value of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    CROSSOVER:
      'The number of left foot entry walks where object is present only on the right side and the left stanchion score is greather than the right stanchion score'
  },
  RIGHT_FOOT_ENTRY_RIGHT_SIDE_OBJECT: {
    ACCURACY:
      '(Number of right foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of right foot entry walks where an object is present on the right side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of right foot entry walks where an object is present on the right side only) [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of right foot entry walks where an object is present on the right side only) [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    MARGIN_CORRECT:
      'The average of (right stanchion score - 50%) for right foot entry walks where object is present on the right side and the right stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (right stanchion score - 50%) for right foot entry walks where object is present on the right side and the right stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    MARGIN_LOW:
      'The lowest value of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    CROSSOVER:
      'The number of right foot entry walks where object is present only on the right side and the left stanchion score is greather than the right stanchion score'
  },
  LEFT_FOOT_ENTRY_LEFT_SIDE_OBJECT: {
    ACCURACY:
      '(Number of left foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of left foot entry walks where an object is present on the left side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      '(Number of left foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of left foot entry walks where an object is present on the left side only) [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      '(Number of left foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of left foot entry walks where an object is present on the left side only) [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    MARGIN_CORRECT:
      'The average of (left stanchion score - 50%) for left foot entry walks where object is present on the left side and the left stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (left stanchion score - 50%) for left foot entry walks where object is present on the left side and the left stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    MARGIN_LOW:
      'The lowest value of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    CROSSOVER:
      'The number of left foot entry walks where object is present only on the left side and the right stanchion score is greather than the left stanchion score'
  },
  RIGHT_FOOT_ENTRY_LEFT_SIDE_OBJECT: {
    ACCURACY:
      '(Number of right foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of right foot entry walks where an object is present on the left side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of right foot entry walks where an object is present on the left side only) [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of right foot entry walks where an object is present on the left side only) [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    MARGIN_CORRECT:
      'The average of (left stanchion score - 50%) for right foot entry walks where object is present on the left side and the left stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (left stanchion score - 50%) for right foot entry walks where object is present on the left side and the left stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    MARGIN_LOW:
      'The lowest value of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    CROSSOVER:
      'The number of right foot entry walks where object is present only on the left side and the right stanchion score is greather than the left stanchion score'
  },
  LEFT_SIDE_OBJECT_RIGHT_FOOT_ENTRY: {
    ACCURACY:
      '(Number of right foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of right foot entry walks where an object is present on the left side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of right foot entry walks where an object is present on the left side only) [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of right foot entry walks where an object is present on the left side only) [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    MARGIN_CORRECT:
      'The average of (left stanchion score - 50%) for right foot entry walks where object is present on the left side and the left stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (left stanchion score - 50%) for right foot entry walks where object is present on the left side and the left stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    MARGIN_LOW:
      'The lowest value of (left stanchion score - 50%) for right foot entry walks where object is present on the left side',
    CROSSOVER:
      'The number of right foot entry walks where object is present only on the left side and the right stanchion score is greather than the left stanchion score'
  },
  LEFT_SIDE_OBJECT_LEFT_FOOT_ENTRY: {
    ACCURACY:
      '(Number of left foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of left foot entry walks where an object is present on the left side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      '(Number of left foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of left foot entry walks where an object is present on the left side only) [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      '(Number of left foot entry walks where an object is present on the left side only and the left stanchion score >= 50%) / (number of left foot entry walks where an object is present on the left side only) [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    MARGIN_CORRECT:
      'The average of (left stanchion score - 50%) for left foot entry walks where object is present on the left side and the left stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (left stanchion score - 50%) for left foot entry walks where object is present on the left side and the left stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    MARGIN_LOW:
      'The lowest value of (left stanchion score - 50%) for left foot entry walks where object is present on the left side',
    CROSSOVER:
      'The number of left foot entry walks where object is present only on the left side and the right stanchion score is greather than the left stanchion score'
  },
  RIGHT_SIDE_OBJECT_RIGHT_FOOT_ENTRY: {
    ACCURACY:
      '(Number of right foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of right foot entry walks where an object is present on the right side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of right foot entry walks where an object is present on the right side only) [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      '(Number of right foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of right foot entry walks where an object is present on the right side only) [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    MARGIN_CORRECT:
      'The average of (right stanchion score - 50%) for right foot entry walks where object is present on the right side and the right stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (right stanchion score - 50%) for right foot entry walks where object is present on the right side and the right stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    MARGIN_LOW:
      'The lowest value of (right stanchion score - 50%) for right foot entry walks where object is present on the right side',
    CROSSOVER:
      'The number of right foot entry walks where object is present only on the right side and the left stanchion score is greather than the right stanchion score'
  },

  RIGHT_SIDE_OBJECT_LEFT_FOOT_ENTRY: {
    ACCURACY:
      '(Number of left foot entry walks where an object is present on the right side only and the right stanchion score >= 50%) / (number of left foot entry walks where an object is present on the right side only)',
    ACCURACY_AVERAGE_MARGIN_BREAKEVEN_BIAS:
      ' [using average margin breakeven sensitivity prediction result]',
    ACCURACY_STANDARD_DEVIATION_BREAKEVEN_BIAS:
      ' [using standard deviation breakeven sensitivity prediction result]',
    AVERAGE_MARGIN:
      'The average of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    VALUE: '',
    STANDARD_DEVIATION:
      'The standard deviation of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    NUM_STANDARD_DEVIATIONS:
      'The average margin / the standard deviation of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    MARGIN_CORRECT:
      'The average of (right stanchion score - 50%) for left foot entry walks where object is present on the right side and the right stanchion score >= 50%',
    MARGIN_INCORRECT:
      'The average of (right stanchion score - 50%) for left foot entry walks where object is present on the right side and the right stanchion score < 50%',
    MARGIN_HIGH:
      'The highest value of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    MARGIN_LOW:
      'The lowest value of (right stanchion score - 50%) for left foot entry walks where object is present on the right side',
    CROSSOVER:
      'The number of left foot entry walks where object is present only on the right side and the left stanchion score is greather than the right stanchion score'
  }
};

export const RADAR_LOCATIONS = [
  {
    index: 0,
    stanchion: 'left_stanchion',
    description: 'Top Entry',
    displayOrder: 0
  },
  {
    index: 1,
    stanchion: 'left_stanchion',
    description: 'Mid Entry',
    displayOrder: 2
  },
  {
    index: 2,
    stanchion: 'left_stanchion',
    description: 'Bottom Entry',
    displayOrder: 4
  },
  {
    index: 3,
    stanchion: 'right_stanchion',
    description: 'Top Entry',
    displayOrder: 6
  },
  {
    index: 4,
    stanchion: 'right_stanchion',
    description: 'Mid Entry',
    displayOrder: 8
  },
  {
    index: 5,
    stanchion: 'right_stanchion',
    description: 'Bottom Entry',
    displayOrder: 10
  },
  {
    index: 6,
    stanchion: 'left_stanchion',
    description: 'Top Exit',
    displayOrder: 1
  },
  {
    index: 7,
    stanchion: 'left_stanchion',
    description: 'Mid Exit',
    displayOrder: 3
  },
  {
    index: 8,
    stanchion: 'left_stanchion',
    description: 'Bottom Exit',
    displayOrder: 5
  },
  {
    index: 9,
    stanchion: 'right_stanchion',
    description: 'Top Exit',
    displayOrder: 7
  },
  {
    index: 10,
    stanchion: 'right_stanchion',
    description: 'Mid Exit',
    displayOrder: 9
  },
  {
    index: 11,
    stanchion: 'right_stanchion',
    description: 'Bottom Exit',
    displayOrder: 11
  }
];

export const ANALYSIS_PREDICTION_TYPES = {
  RECORDED: 'RECORDED',
  MODEL_CONFIGURATION: 'MODEL_CONFIGURATION'
};

export const CELL_CONFIG_START_MODES = {
  AUTO_SAVE: 'Auto Save (Common)',
  SESSION: 'Test/Training Session',
  ANALYSIS_QUEUE: 'Analysis Queue',
  AUTO_DISCARD: 'Auto Discard'
};

export const CELL_CONFIG_LED_COLORS = {
  RED: 'Red',
  BLUE: 'Blue'
};

export const SCENARIO_TYPES = [
  {
    code: 'TESTING',
    name: 'Testing',
    uriPart: 'testing',
    lowerCaseName: 'testing',
    listPage: 'TEST_SCENARIO_LIST',
    supportsValidation: false,
    supportsAnalysis: true,
    supportsMasterVersion: true,
    supportsObjectPercent: true
  },
  {
    code: 'TRAINING',
    name: 'Data Collection',
    uriPart: 'training',
    lowerCaseName: 'training',
    listPage: 'TRAINING_SCENARIO_LIST',
    supportsValidation: true,
    supportsAnalysis: false,
    supportsMasterVersion: false,
    supportsObjectPercent: false
  }
];

export const ENTITY_TYPES = {
  SETUP_SCENARIO_SESSION: 'SETUP_SCENARIO_SESSION',
  EVENT: 'EVENT',
  PARTICIPANT: 'PARTICIPANT',
  CELL: 'CELL',
  CELL_COMPONENT: 'CELL_COMPONENT',
  CUSTOMER: 'CUSTOMER',
  VENUE: 'VENUE',
  CUSTOMER_EVENT: 'CUSTOMER_EVENT',
  EVENT_DATA_SET: 'EVENT_DATA_SET',
  MANUAL: 'MANUAL'
};

export const ENTITY_TYPE_DETAILS_URIS = {
  SETUP_SCENARIO_SESSION: 'testing/sessions',
  CUSTOMER_EVENT: 'events/customer_events',
  EVENT_DATA_SET: 'walks/data_sets'
};

export const CELL_COMPONENT_TYPES = {
  SOFTWARE: 'Software',
  HARDWARE: 'Hardware',
  MODEL: 'Model'
};

export const CELL_STATUSES = {
  PRE_BUILD: 'Pre Build',
  BUILD_ACTIVE: 'Build Active',
  PRE_PRODUCTION: 'Pre Production',
  DEVELOPMENT: 'Development',
  LAB_PRODUCTION: 'Training Loop',
  FIELD_PRODUCTION: 'Field Production',
  DECOMMISSIONED: 'Decommissioned',
  DISASSEMBLED: 'Disassembled',
  MARKETING: 'Marketing',
  TESTING: 'Testing',
  VERIFIED: 'Verified'
};

export const CELL_MODES = {
  PROD: 'PROD',
  UAT: 'UAT',
  TEST: 'TEST',
  DEV: 'DEV'
};

export const SESSION_DURATION_TYPES = {
  OPEN: 'Open',
  WALK_COUNT: 'Walk Count'
};

export const CELL_LOG_ENTRY_LEVELS = ['CRITICAL', 'SERIOUS', 'WARNING', 'INFO'];

export const CELL_SYSTEM_PROPERTIES = {
  computerSystemModel: 'System',
  cpuName: 'CPU',
  cpuHighTemp: 'CPU High Temp Alert',
  cpuCurrentTemp: 'CPU Current Temp',
  cpuCriticalTemp: 'CPU Critical Temp Alert',
  osName: 'OS',
  osVersion: 'OS Version',
  osKernelVersion: 'OS Kernel Version',
  memoryAvailable: 'Available Memory',
  memoryTotal: 'Total Memory',
  gpuCurrentTemp: 'GPU Current Temp',
  smartDiskStatus_nvme0n1: 'Disk Status (nvme0n1)'
};

export const CELL_DEPLOYMENT_TYPES = {
  FIELD: 'Field',
  TRAINING: 'Training'
};

export const CELL_ALERT_TYPES = {
  DISK_USAGE: 'Disk Usage',
  DISK_HEALTH: 'Disk Health',
  GPU_TEMPERATURE: 'GPU Temperature',
  CPU_TEMPERATURE: 'CPU Temperature',
  KERNEL_VERSION: 'OS Kernel Version'
};

export const SOFTWARE_PACKAGE_STATUSES = {
  IN_DEVELOPMENT: 'Development',
  IN_TESTING: 'In Testing',
  TESTING_COMPLETE: 'Testing Complete',
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED: 'Approved',
  DEPRECATED: 'Deprecated',
  DELETED: 'Deleted'
};

export const SOFTWARE_PACKAGE_STATUS_COLORS = {
  IN_DEVELOPMENT: colors.blue[600],
  IN_TESTING: colors.teal[600],
  TESTING_COMPLETE: colors.purple[600],
  PENDING_APPROVAL: colors.orange[600],
  APPROVED: colors.green[600]
};

export const CELL_CHECK_LIST_STATUSES = {
  INCOMPLETE: 'Incomplete',
  COMPLETED: 'Completed'
};

export const SCORE_SIDES = {
  LEFT_SIDE: 'Left Side',
  RIGHT_SIDE: 'Right Side',
  BOTH_SIDES: 'Both Sides'
};

export const CAMERA_FACING_MODES = {
  USER: 'user',
  ENVIRONMENT: 'environment'
};

export const CELL_HOST_TYPES = {
  CLIENT: 'Client',
  SERVER: 'Server',
  COMBINED: 'Combined'
};

export const ANALYTICS_OBJECT_LOCATIONS = {
  NO_OBJECT: 'No Object',
  HEAD: 'Head',
  RIGHT_SIDE: 'Right Side',
  LEFT_SIDE: 'Left Side',
  BOTH_SIDES: 'Both Sides',
  RIGHT_CHEST: 'Right Chest',
  LEFT_CHEST: 'Left Chest',
  BOTH_CHESTS: 'Both Chests',
  FRONT: 'Front',
  BACK: 'Back',
  BAG: 'Bag'
};

export const MODEL_RUN_REQUEST_EVENT_TYPES = {
  CUSTOMER_EVENT: 'Customer Event',
  TEST_SESSION: 'Test Session',
  TRAINING_SESSION: 'Training Session'
};

export const TIME_FREQUENCIES = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly'
};

export const SESSION_STATUSES = {
  CLOSED: 'Closed',
  OPEN: 'Open'
};

export const EVENT_SESSION_STATUSES = {
  INCLUDED: 'Included',
  EXCLUDED: 'Excluded',
  SETUP_EXCLUDED: 'Setup Excluded',
  UNLINKED: 'Unlinked'
};

export const SETUP_ANALYTICS_STATUS = {
  INCLUDED: 'Included',
  EXCLUDED: 'Excluded'
};

export const SESSION_WALK_STATUSES = {
  INCOMPLETE: 'Incomplete',
  COMPLETE: 'Complete'
};

export const SESSION_VALIDATION_STATUSES = {
  PENDING: 'Pending',
  VERIFIED: 'Verified'
};

export const SETUP_VALIDATION_STATUSES = {
  UNLINKED: 'Unlinked',
  PENDING: 'Pending',
  VERIFIED: 'Verified',
  SUSPECT: 'Excluded'
};

export const EVENT_VALIDATION_STATUS_CODES = {
  pending: 'PENDING',
  verified: 'VERIFIED',
  suspect: 'Excluded',
  testing: 'TESTING'
};

export const EVENT_VALIDATION_STATUSES = {
  PENDING: 'Pending',
  VERIFIED: 'Verified',
  SUSPECT: 'Excluded',
  TESTING: 'Testing'
};

export const TRAINING_VALIDATION_STATUSES = {
  PENDING: 'Pending',
  VERIFIED: 'Verified',
  SUSPECT: 'Excluded'
};

export const EVENT_DATA_SET_STATUSES = {
  PROCESSING: 'Processing',
  READY: 'Ready',
  FAILED: 'Failed'
};

export const MODEL_SET_CALUCLATION_METHODS = {
  WEIGHTS_FIRST: 'Weights First',
  MAX_FIRST: 'Max Score First'
};

export const S3_FILE_TYPES = {
  SENSOR_DATA: 'Sensor Data',
  WALK_INFO: 'Walk Info',
  WALK_VIDEO: 'Video',
  ENTRY_IMAGE: 'Entry Image',
  COMBINED_ENTRY_EXIT_IMAGE: 'Combined Exit/Entry Image',
  EXIT_IMAGE: 'Exit Image',
  SENSOR_DATA_IMAGE: 'Sensor Data Image',
  POSE_ESTIMATION_IMAGE: 'Pose Estimation'
};

export const MODEL_AREAS = {
  LEFT_GENERAL: 'Left Gen.',
  LEFT_ANKLE: 'Left Ankle.',
  RIGHT_GENERAL: 'Right Gen.',
  RIGHT_ANKLE: 'Right Ankle'
};

export const EVENT_MODEL_WORKER_STATUSES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const EVENT_MODEL_WORKER_ALERT_STATUSES = {
  ALERT: 'Alert',
  ESCALATION: 'Escalation'
};

export const ENVIRONMENTS = {
  DEV: 'Development',
  UAT: 'UAT',
  PROD: 'Production'
};

export const NEW_ITEM_TRIGGER = '__NEW_ITEM__';

export const TIME_POINT_DESCRIPTIONS = {
  exitLEDsOff: { description: 'Exit LEDs Off' },
  updateEventBB: { description: 'Update Event BB' },
  updateEventLIDARfilteredPos: {
    description: 'Update Event LIDAR Filtered Pos'
  },
  centerEventLIDAR: { description: 'Center Event LIDAR' },
  'calculateMotionModels (LIDAR motion models)': {
    description: 'Calculate Motion Models (LIDAR Motion Models)'
  },
  'alignEventFrames (radar motion models)': {
    description: 'Align Event Frames (Radar Motion Models)'
  },
  createEventBundles: { description: 'Create Event Bundles' },
  'model predictions': { description: 'Model Predictions' },
  'gp array': { description: 'GP Array' },
  'Result on LEDs!': { description: 'Result on LEDs' },
  'debug gun percentages on UI': {
    description: 'Debug Gun Percentages on UI',
    excluded: true
  },
  'Large number display': {
    description: 'Large Number Display',
    excluded: true
  },
  'MS David': { description: 'Speak Score', excluded: true },
  'update DebugLevel graphs': {
    description: 'Update Debug Level Graphs',
    excluded: true
  },
  verifyWalkCharacteristics: {
    description: 'Verify Walk Characteristics (Speed, Gait etc.)'
  },
  createInsightTestSessionEventApiCall: {
    description: 'Create Insight Session Event Api Call',
    excluded: true
  }
};

export const DEFAULT_MODEL_CONFIG_SETTINGS_JSON =
  '{\n' +
  '    "bias": 50,\n' +
  '    "gait": "Off",\n' +
  '    "LIDARadjFit": "Off",\n' +
  '    "use_DeepCNN_predictionModel": false,\n' +
  '    "predSource": "votingPrediction_deep_meanBundles",\n' +
  '    "LIDARenableDetrendedLowpass": false,\n' +
  '    "centerEventLIDAR_frameLogic": {\n' +
  '        "restrictRange_1x2": false,\n' +
  '        "radarCentered": true,\n' +
  '        "strictFrameCount": 40\n' +
  '    }\n' +
  '}';

export const MALE_BMI_DISTRIBUTIONS = {
  BMI_CAT_1: 0.171,
  BMI_CAT_2: 0.266,
  BMI_CAT_3: 0.295,
  BMI_CAT_4: 0.15,
  BMI_CAT_5: 0.118
};

export const FEMALE_BMI_DISTRIBUTIONS = {
  BMI_CAT_1: 0.171,
  BMI_CAT_2: 0.174,
  BMI_CAT_3: 0.387,
  BMI_CAT_4: 0.172,
  BMI_CAT_5: 0.096
};

export const MALE_HEIGHT_DISTRIBUTIONS = {
  BMI_CAT_1: 0.1473,
  BMI_CAT_2: 0.211,
  BMI_CAT_3: 0.285,
  BMI_CAT_4: 0.2199,
  BMI_CAT_5: 0.1368
};

export const FEMALE_HEIGHT_DISTRIBUTIONS = {
  BMI_CAT_1: 0.1507,
  BMI_CAT_2: 0.2495,
  BMI_CAT_3: 0.297,
  BMI_CAT_4: 0.2075,
  BMI_CAT_5: 0.0953
};
