import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  cellLogEntry: {}
};

const cellLogEntryDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CELL_LOG_ENTRY_PENDING: {
      return {
        ...state,
        loading: true,
        cellLogEntry: initialState.cellLogEntry
      };
    }

    case actionTypes.FIND_CELL_LOG_ENTRY_FULFILLED: {
      console.log('Aaaaaaargh', action.payload.data.result);

      return {
        ...state,
        loading: false,
        cellLogEntry: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default cellLogEntryDetailsReducer;
